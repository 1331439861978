import en from './en.json'
import es from './es.json'
import de from './de.json'
import pt from './pt.json'
import fr from './fr.json'

export default {
  en: en,
  es: es,
  de: de,
  pt: pt,
  fr:fr
}
