// Import all register end points
import { ClientDataEP } from './clientDataEP';
// Import all contact end points
import { ContactEP } from './contactEP';
// Import all login end points
import { LoginEP } from './loginEP';
// Import all Init end points
import { InitEP } from './initEP';
// Import all Regularizations end points
import { RegularizationsEP } from './regularizationsEP';
// Import all ranking end points
import { TopRankingEP } from './topRankingEP';
// Import all News end points
import { NewsEP } from './newsEP';
// Import all bookings end points
import { BookingsEP } from './bookingsEP'
import {LegalTextsEP} from './legalTextsEP'
// Import all agencies ep
import {AgenciesEP} from './AgenciesEP'
import {FaqsEP} from './faqsEP'
import { KnowUsEP } from './KnowUsEP'
import {BankDataEP} from './BankDataEP'
import {AgentsManagementEP} from './AgentsManagementEP'
import {LocationEP} from './LocationEP'
import {CurrenciesEP} from './CurrenciesEP'
import {RewardsConfigurationEP} from './RewardsConfigurationEP'
import {NotificationsEP} from './ErrorsEP'



export const RegisterEndPoints = []
        .concat(ContactEP)
        .concat(FaqsEP)
        .concat(LoginEP)
        .concat(InitEP)
        .concat(RegularizationsEP)
        .concat(TopRankingEP)
        .concat(NewsEP)
        .concat(BookingsEP)
        .concat(ClientDataEP)
	.concat(AgenciesEP)
        .concat(LegalTextsEP)
        .concat(KnowUsEP)
        .concat(BankDataEP)
        .concat(AgentsManagementEP)
        .concat(LocationEP)
        .concat(CurrenciesEP)
        .concat(RewardsConfigurationEP
        .concat(NotificationsEP)
        )
        
        
 