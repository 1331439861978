import React, { Suspense } from "react";
import App from "../components/app/app";
import "../i18n";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryComponent from "src/components/ErrorBoundaryComponent/ErrorBoundaryComponent";
import Error500 from "src/components/utils/errors/500/500";
import Error404 from "src/components/utils/errors/404/404";
import Error403 from "src/components/utils/errors/403/403";

const AppWrapper = () => {

  function myErrorFallBack({error} : any){

    console.log("Error: ", error);

    // Get propreties from error to know what attributes are available
    var errorKeys = Object.keys(error);
    console.log("Error keys: ", errorKeys);

    var errorMessage = error.message ?? error.payload;
    var errorStackTrace = error.stack ?? error.status;

    console.log("Error errorMessage: ", errorMessage);
    console.log("Error errorStackTrace: ", errorStackTrace);

    var actualError = sessionStorage.getItem("last-error");

    if (actualError){
      const errors = JSON.parse(actualError);
      errors.push({ message: encodeURIComponent(error.message), method: 'generic-error', parameters: JSON.stringify(error.stack) });
      sessionStorage.setItem("last-error", JSON.stringify(errors));
    }else{
      sessionStorage.setItem("last-error", JSON.stringify([{ message: encodeURIComponent(error.message), method: 'generic-error', parameters: JSON.stringify(error.stack) }]));
    }
    
    return <Error500 error={error}/>;
  }


  return (
    <Suspense fallback={null}>
      <ErrorBoundary FallbackComponent={myErrorFallBack}>
        <App />
      </ErrorBoundary>
    </Suspense>
  );
};

export default AppWrapper;
