import React, { useContext, useEffect } from 'react';

import './500.scss';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormattedMessage } from 'react-intl';
import { RestApiClientContext } from 'src/hooks/context/pageContext';

interface Error500ComponentProps{
    error: any
}


const Error500 = (props: Error500ComponentProps) : JSX.Element => {
    const restApiClient = useContext(RestApiClientContext);

    const { t } = useTranslation();

    const errorData = {
        tenantURL: window.location.href,
        errorCode: generateUUID(),
        message: props.error.message,
        stackTrace: JSON.stringify(props.error.stack)
    }

    const sendNotificationsErrorEmail = () : void => {
      restApiClient.sendNotificationsErrorEmail(errorData).then(v => {});
    }

    useEffect(()=>{
        sendNotificationsErrorEmail();
    },[])
    

    const removeErrorFromSessionStorage = () : void => {
        sessionStorage.removeItem("last-error");
    }

    const goHome = () : void => {
        removeErrorFromSessionStorage();
        const url : string = "/";
        window.location.href = url;
    }

    const reload = () : void => {
        removeErrorFromSessionStorage();
        //Reload the page
        window.location.reload();
    }

    function generateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    console.log("Error500.tsx: Error500", props, errorData);

    return(
        <div className="error-page generic-message">
            <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                    <path d="M36.2059 11.172C36.2296 11.1676 36.2542 11.163 36.2813 11.163C40.9043 11.163 44.6543 14.913 44.6543 19.535C44.6543 24.157 40.9043 27.908 36.2813 27.908H8.37329C3.75129 27.908 0.00128859 24.157 0.00128859 19.535C0.00128859 15.829 2.42629 12.715 5.76629 11.616C5.65229 11.015 5.58329 10.404 5.58329 9.767C5.58329 4.369 9.96129 0 15.3503 0C19.1273 0 22.3623 2.162 23.9853 5.302C25.2673 3.776 27.1593 2.79 29.3043 2.79C33.1593 2.79 36.2813 5.913 36.2813 9.767C36.2813 10.256 36.2383 10.727 36.1423 11.18C36.1634 11.18 36.1842 11.1761 36.2059 11.172ZM7.66729 32.277C7.00429 31.893 6.15029 32.12 5.76629 32.8L0.184289 42.568C-0.199711 43.24 0.0362886 44.094 0.707289 44.469C0.926289 44.591 1.16129 44.652 1.39629 44.652C1.87629 44.652 2.34729 44.399 2.60929 43.946L8.19029 34.178C8.57429 33.515 8.33929 32.661 7.66729 32.277ZM14.1383 32.8C14.5223 32.12 15.3773 31.893 16.0393 32.277C16.7113 32.661 16.9463 33.515 16.5633 34.178L10.9813 43.946C10.7193 44.399 10.2493 44.652 9.76929 44.652C9.53329 44.652 9.29829 44.591 9.08029 44.469C8.40829 44.094 8.17329 43.24 8.55729 42.568L14.1383 32.8ZM22.5113 32.8C22.8943 32.12 23.7493 31.893 24.4123 32.277C25.0833 32.661 25.3193 33.515 24.9353 34.178L19.3543 43.946C19.0923 44.399 18.6213 44.652 18.1413 44.652C17.9063 44.652 17.6703 44.591 17.4523 44.469C16.7813 44.094 16.5453 43.24 16.9293 42.568L22.5113 32.8ZM39.2553 32.8C39.6393 32.12 40.4943 31.893 41.1573 32.277C41.8283 32.661 42.0643 33.515 41.6803 34.178L36.0983 43.946C35.8373 44.399 35.3663 44.652 34.8863 44.652C34.6513 44.652 34.4153 44.591 34.1973 44.469C33.5263 44.094 33.2903 43.24 33.6743 42.568L39.2553 32.8ZM32.7843 32.277C32.1213 31.893 31.2673 32.12 30.8833 32.8L25.3013 42.568C24.9183 43.24 25.1533 44.094 25.8253 44.469C26.0433 44.591 26.2783 44.652 26.5143 44.652C26.9933 44.652 27.4643 44.399 27.7263 43.946L33.3083 34.178C33.6913 33.515 33.4563 32.661 32.7843 32.277Z" fill="#424C53"/>
                </svg>
            </div>
            <div className="message">
                <p className="title">
                    <FormattedMessage id="error.title" />
                </p>
                <p className="text">
                    <FormattedMessage id="error.text" />
                </p>
                <p className="text">
                    <FormattedMessage id="error.code" values={{code: errorData.errorCode }}/>
                </p>
            </div>
            <div className="buttons">
                <Button className="app-button" onClick={goHome}>
                    <div className="content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                            <path d="M18 9.83429L9 2.84829L0 9.83433V6.986L9 0L18 6.98604V9.83429ZM15.75 9.58039V16.3304H11.25V11.8304H6.75V16.3304H2.25V9.58039L9 4.51789L15.75 9.58039Z" fill="white"/>
                        </svg>
                        <span>
                            <FormattedMessage id="home" />
                        </span>
                    </div>
                </Button>
                <Button onClick={reload}>
                    <div className="content">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <g >
                        <path d="M9.00001 1.63079L16.5428 16.6641H1.45716L9.00001 1.63079ZM9.00001 0C8.61206 0 8.22411 0.261598 7.92978 0.784828L0.246839 16.0973C-0.341783 17.1438 0.158948 18 1.3596 18H16.6404C17.841 18 18.3418 17.1438 17.7531 16.0973H17.7531L10.0702 0.784828C9.77591 0.261598 9.38796 0 9.00001 0Z" fill="white"/>
                        <path d="M10.125 14.625C10.125 15.2463 9.62132 15.75 9 15.75C8.37868 15.75 7.875 15.2463 7.875 14.625C7.875 14.0037 8.37868 13.5 9 13.5C9.62132 13.5 10.125 14.0037 10.125 14.625Z" fill="white"/>
                        <path d="M9 12.375C8.37868 12.375 7.875 11.8713 7.875 11.25V7.875C7.875 7.25368 8.37868 6.75 9 6.75C9.62132 6.75 10.125 7.25368 10.125 7.875V11.25C10.125 11.8713 9.62132 12.375 9 12.375Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_51_2205">
                            <rect width="18" height="18" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                        <span>
                            <FormattedMessage id="reload" />
                        </span>
                    </div>
                </Button>
            </div>
        </div>
    )
};

export default Error500;