


import EndPointConfig from "src/models/api/endPointConfig";

const controller = 'Notifications';

/// Define all EndPoints for Client Data.
export const NotificationsEP : EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setAlias('NotifyErrorToAdmin')
        .setActionName("NotifyErrorToAdmin"),
];

